:root{
    --lightgray: #D9D9D9;
    --gray: #9c9c9c;
    --orange: #74b9ff;
    --darkGray: #464D53;
    --caloryCard: #656565;
    --planCard: linear-gradient(210.41deg, #74b9ff 1.14%, #1865b3 100.74%);
    --appColor: #555961;
}

@font-face {
    font-family: yekan;
    src: url('./fonts/Yekan.ttf');
}
@font-face {
    font-family: tv;
    src: url('./fonts/Far_TV.ttf');
}

.App{
    /* background-image: url(); */
    mix-blend-mode: overlay;
    background-color: var(--appColor);
    display: flex;
    flex-direction: column;
    gap: 6rem;
    overflow: hidden;
    font-family: yekan;
    direction: rtl;
}

.blur{
    background: rgba(63, 158, 235, 0.69);
    position: absolute;
    border-radius: 50%;
    filter: blur(150px);
    z-index: -9;
}

::-webkit-scrollbar{
    display: none;
}

.stroke-text{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;

}

.btn{
    background-color: white;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn:hover{
    cursor: pointer;
}